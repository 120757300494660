import { THUMBNAIL_SIZE_LARGE } from '@embed/styled'
import testWebpSupport from '@player/utils/testWebpSupport'

let WEBP_SUPPORTED: undefined | boolean = undefined
testWebpSupport((result: boolean): void => {
  WEBP_SUPPORTED = !!result
})

interface IDefaults {
  format: string
  quality: number
  size: number[]
}

/**
 * @property format? - (webp | jpg) the image format of the image to resize
 * @property quality? - (1-100) a quality for the resized image
 * @property size[]? - [width,height] - a width and height for the resulting image
 */
export interface ThumbnailResizeOptions {
  format?: string
  quality?: number | boolean
  size?: number[]
}

/**
 * Takes the url to an image and returns a new url with appropriate resizing params,
 * or same url if the image can't be resized. Will return `url` unchanged if
 * `default_avatar` or `default_event_trailer` present
 *
 * @param url - a URL to an image to possibly resize
 * @param options - any resizing options
 */
export const resizeThumbnailUrl = (
  url: string | null | undefined,
  options: ThumbnailResizeOptions = {},
): string => {
  if (!url) {
    return ''
  }

  if (
    url &&
    (url.match('default_avatar') || url.match('default_event_trailer'))
  ) {
    // User has not set an avatar, the default avatar returned in api cannot be
    // dynamically resized. Same goes with default event trailer poster.
    return url
  }

  if (url.includes('/000')) {
    url = url.replace('/000/', '/540/')
  }

  const defaults: IDefaults = {
    format: WEBP_SUPPORTED ? 'webp' : 'jpg',
    quality: 62, // deprecated qualities -> [61]
    size: [THUMBNAIL_SIZE_LARGE[0], THUMBNAIL_SIZE_LARGE[1]],
  }
  const { format, quality, size }: ThumbnailResizeOptions = {
    ...defaults,
    ...(options || {}),
  }

  let append = ''
  append += quality ? `.${quality}` : ''
  append += format ? `.${format}` : ''

  const match = url.match(/\/transcoded\/(?<width>\d{3,4})\//i)
  let output = ''

  if (size) {
    if (match?.groups?.width) {
      output =
        url.replace(
          `transcoded/${match.groups.width}/`,
          `transcoded/${size[0]}_${size[1]}/`,
        ) + append
    } else {
      output =
        url.replace(
          /\/(original|\d{3,4}[_|x]\d{3,4})\//,
          `/${size[0]}_${size[1]}/`,
        ) + append
    }
  } else {
    output = url + append
  }

  const outputURL = new URL(output)

  return outputURL.toString()
}
