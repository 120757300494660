import { logError, logWarning } from '@core/logging'
import {
  EnumFunnelTrackingCookies,
  setCookieWithFallback,
} from '@core/tracking/funnelTracking'
import { getPageUrl } from '@core/window'

/**
 * Persist the business membership id inside storage.
 * @param bmid Business membership id. If not provided, the function will check
 *     if current url has fw_bmid query param
 */
export function persistBusinessMembershipId(bmid?: string) {
  if (!bmid) {
    const url = getPageUrl()
    try {
      if (!url) {
        return
      }

      const urlObject = new window.URL(url)
      bmid = urlObject.searchParams.get('fw_bmid') ?? undefined
    } catch (e) {
      if (
        e instanceof TypeError &&
        e.message.includes('is not a constructor')
      ) {
        logWarning(
          `Firework failed to persist Business Membership ID. See error:`,
          e,
        )
      } else {
        logError(e, {
          captureContext: {
            extra: {
              url,
            },
          },
        })
      }
    }
  }

  if (bmid) {
    setCookieWithFallback(
      EnumFunnelTrackingCookies.FW_BUSINESS_MEMBERSHIP_ID,
      bmid,
    )
  }
}
